.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 8rem;
  height: 1.375rem;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: yellow;
  border: none;
  outline: none;
  background: transparent;
  z-index: 200;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: yellow;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 800;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0.625rem;

}

[dir='rtl'] .slick-prev {
  right: -1.5625rem;

}

.slick-prev:before {
  content: url('../icons/rigth.svg');
}

[dir='rtl'] .slick-prev:before {
  content: url('../icons/rigth.svg');
}

.slick-next {
  right: 0.625rem;
}

[dir='rtl'] .slick-next {
  left: -1.5625rem;
}

.slick-next:before {
  content: url("../icons/left.svg");
}

[dir='rtl'] .slick-next:before {
  content: url("../icons/left.svg");
}

.slick-dotted.slick-slider {
  margin-bottom: 1.875rem;
  padding: 0;
}

.slick-dots {
  position: absolute;
  bottom: 4rem;
  display: block;
  width: 100%;
  padding: 0;
  margin: auto;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 6px;
  padding: 0;
  border-radius: 50%;
  background: #e9e9f0;
  overflow: hidden;

  cursor: pointer;

  &.slick-active {
    background: #ffc000;
    padding: 1.5px;
    color: transparent;
    &:before {
      opacity: 0.1;
    }
  }
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  padding: 0;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
  padding: 0;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  opacity: 1;
  padding: 0;
content: '';
}

.slick-dots li.slick-active button:before {
  margin-top: 0.0625rem;
  padding: 0;
  opacity: 1;
  content: '';
}
